@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html * {
  box-sizing: border-box;
}

body {
  height: 100%;
  font-family: "interui", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-weight: 400;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

#root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate {
  animation: spin 2000ms linear infinite;
}
