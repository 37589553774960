@font-face {
  font-family: "interui";
  font-weight: 300;
  src: url("../fonts/interui/Inter-Light-BETA.woff2") format("woff2"),
    url("../fonts/interui/Inter-Light-BETA.woff") format("woff");
}

@font-face {
  font-family: "interui";
  font-weight: 400;
  src: url("../fonts/interui/Inter-Regular.woff2") format("woff2"),
    url("../fonts/interui/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: "interui";
  font-weight: 500;
  src: url("../fonts/interui/Inter-Medium.woff") format("woff2"),
    url("../fonts/interui/Inter-Medium.woff2") format("woff");
}

@font-face {
  font-family: "interui";
  font-weight: 600;
  src: url("../fonts/interui/Inter-SemiBold.woff") format("woff2"),
    url("../fonts/interui/Inter-SemiBold.woff2") format("woff");
}

@font-face {
  font-family: "interui";
  font-weight: 700;
  src: url("../fonts/interui/Inter-Bold.woff") format("woff2"),
    url("../fonts/interui/Inter-Bold.woff2") format("woff");
}

@font-face {
  font-family: "interui";
  font-weight: 800;
  src: url("../fonts/interui/Inter-ExtraBold.woff") format("woff2"),
    url("../fonts/interui/Inter-ExtraBold.woff2") format("woff");
}
